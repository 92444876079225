import axiosBase, { AxiosInstance } from 'axios';

import { AppConfig } from 'config';

import {
  GetOnBoardingResponse,
  IAditionalProductsResponse,
  IAuthSignupResponse,
  IAuthVerifyOrChangeEmailResponse,
  ICancelPaypalSubscriptionRequest,
  ICancelPaypalSubscriptionResponse,
  ICancelPayproSubscriptionResponse,
  ICancelStripeSubscriptionRequest,
  ICancelStripeSubscriptionResponse,
  IConfirmPaypalSubscriptionResponse,
  IGetAllSubscriptionsResponse,
  IPaypalSubscriptionCreateRequest,
  IPayproPushbackResponse,
  IProductResponse,
  IStripeCreateSubscriptionRequest,
  IStripeCreateSubscriptionResponse,
  IstripeGetSetupIntentRequest,
  IStripeGetPricesRequest,
  IStripeRetryInvoiceRequest,
  OnboardingResultDTO,
  SensesServerReponse,
  WithData,
} from 'types/types';

import { handleDefaultInterception } from 'services/networkError';
import { FirebaseService } from 'services/remoteConfig';

import { APP_ID } from 'constants/common';

class Client {
  url: string;
  mediaUrl: string;
  baseUrl: string;
  version: string;
  axios: AxiosInstance;
  applicationId: string;

  constructor(core: string, media: string, applicationId: string) {
    this.url = core;
    this.mediaUrl = media;
    this.applicationId = applicationId;
    this.version = 'v1';
    this.baseUrl = `${core}/api/${this.version}`;
    this.axios = axiosBase.create({ baseURL: this.baseUrl });
    this.axios.interceptors.response.use(
      response => response,
      handleDefaultInterception,
    );
  }

  setAuthKey(newKey: string) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${newKey}`;
  }

  async getOnboarding(onboardingId: string) {
    const version = 'v4';
    const url = `${this.mediaUrl}/api/${version}/web/onboarding/${onboardingId}`;
    const result = await this.axios.get<
      SensesServerReponse<GetOnBoardingResponse>
    >(url);

    return result.data.result;
  }

  async postOnboardingResult(body: OnboardingResultDTO) {
    const version = 'v2';
    const url = `${this.mediaUrl}/api/${version}/web/onboarding`;
    const result = await this.axios.post<{ result: boolean }>(url, body);
    return result.data;
  }

  async signup(email: string) {
    const result = await this.axios.post<IAuthSignupResponse>(
      '/auth/email/landing/signup',
      {
        email,
        bundleId: AppConfig.BUNDLE_ID,
        route: FirebaseService.page,
        subdomain: window.location.hostname.split('.')?.[0] || 'app',
      },
    );
    return result.data;
  }

  async verifyEmail(email: string, digitCode: string) {
    const result = await this.axios.post<IAuthVerifyOrChangeEmailResponse>(
      '/auth/email/landing/verify',
      { email, digitCode, bundleId: AppConfig.BUNDLE_ID },
    );
    return result.data;
  }

  async changeEmail(email: string) {
    const result = await this.axios.post<IAuthVerifyOrChangeEmailResponse>(
      '/auth/email/landing/change-email',
      { email },
    );
    return result.data;
  }

  async stripeSubscriptionCreate(body: IStripeCreateSubscriptionRequest) {
    const result = await this.axios.post<IStripeCreateSubscriptionResponse>(
      `/stripe/pay/${this.applicationId}`,
      body,
    );
    return result.data;
  }

  async stripeRetryInvoice(body: IStripeRetryInvoiceRequest) {
    const result = await this.axios.post<any>(
      `/stripe/retry-invoice/${this.applicationId}`,
      body,
    );
    return result.data;
  }

  async stripeGetPrices(body: IStripeGetPricesRequest) {
    const result = await this.axios.post<any>(
      `/stripe/prices/${this.applicationId}`,
      body,
    );
    return result.data;
  }

  async stripeGetSetupIntent(body: IstripeGetSetupIntentRequest) {
    const result = await this.axios.post<any>(
      `stripe/setupIntent/${this.applicationId}`,
      body,
    );
    return result.data;
  }

  async stripeGetPaymentIntet(body: IstripeGetSetupIntentRequest) {
    const result = await this.axios.post<any>(
      `stripe/setupIntent/${this.applicationId}`,
      body,
    );
    return result.data;
  }

  async paypalSubscriptionCreate(params: IPaypalSubscriptionCreateRequest) {
    const result = await this.axios.post<any>(
      `/paypal/subscription-create/${this.applicationId}`,
      params,
    );
    return result.data;
  }

  async getAllSubscriptions() {
    const result = await this.axios.get<IGetAllSubscriptionsResponse>(
      '/detailed-subscriptions/web/all',
    );
    return result.data;
  }

  async getProducts(productIds: string[]): Promise<IProductResponse> {
    const result = await this.axios.post<IProductResponse>('products/web', {
      productIds,
      applicationId: this.applicationId,
    });
    return result.data;
  }

  async cancelStripeSubscription(body: ICancelStripeSubscriptionRequest) {
    const result = await this.axios.post<ICancelStripeSubscriptionResponse>(
      `stripe/cancel/${this.applicationId}`,
      body,
    );
    return result.data;
  }

  async cancelPaypalSubscription(
    subscriptionId: ICancelPaypalSubscriptionRequest,
  ) {
    const result = await this.axios.post<ICancelPaypalSubscriptionResponse>(
      `paypal/subscription-cancel/${this.applicationId}`,
      { subscriptionId },
    );
    return result.data;
  }

  async cancelPayproSubscription(subscriptionId: string) {
    const result = await this.axios.post<ICancelPayproSubscriptionResponse>(
      `paypro/cancel/${this.applicationId}`,
      { subscriptionId },
    );
    return result.data;
  }

  async confirmPaypalSubscription(subscriptionIds: string[]) {
    const result = await this.axios.post<IConfirmPaypalSubscriptionResponse>(
      `paypal/subscription-confirm/${this.applicationId}`,
      { subscriptionIds },
    );
    return result.data;
  }

  async payproPool(productId: string) {
    const result = await this.axios.post<IPayproPushbackResponse>(
      `paypro/pushback/${this.applicationId}`,
      { productId },
    );
    return result.data;
  }

  async getAdditionalProduct() {
    const result = await this.axios.get<IAditionalProductsResponse>(
      `paypro/additional-product/${this.applicationId}`,
    );
    return result.data.data;
  }

  async getUserAdditionalProduct() {
    const result = await this.axios.get<IAditionalProductsResponse>(
      'paypro/additional-product',
    );
    return result.data.data;
  }

  async upgradeSubscription(oldProductId: string, newProductId: string) {
    const result = await this.axios.post<{ data: boolean; result: boolean }>(
      'paypro/upgrade-subscription',
      {
        productId: oldProductId,
        newProductId,
      },
    );
    return { ...result.data, status: result.status };
  }

  async buyAdditionalProduct(productId: string): Promise<{
    data: boolean;
    result: boolean;
    status: number;
    statusText: string;
  }> {
    const result = await this.axios.post<{ data: any; result: boolean }>(
      `paypro/additional-product/${this.applicationId}`,
      { productId },
    );
    return {
      ...result.data,
      status: result.status,
      statusText: result.statusText,
    };
  }

  async mailingDisable(accountId: string) {
    const result = await this.axios.get<
      WithData<{
        _id: string;
        application: string;
        devices: any[];
        mergedAccountIds: any[];
        platform: string;
        existsSubscription: boolean;
        allowMailing: boolean;
        creationDate: string;
        __v: number;
      }>
    >(`mailing/disable/${accountId}`);
    return result.data;
  }
}

export const SensesSDK = new Client(
  AppConfig.API_URL,
  AppConfig.SENSES_MEDIA_API_URL,
  APP_ID,
);
