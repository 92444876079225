import { pluralization } from 'utils/formaters';

export const purchaseEventPeriod = (
  _interval: string,
  intervalCount: number,
) => {
  let interval = _interval.toLowerCase();

  return `${intervalCount} ${pluralization(
    intervalCount,
    interval,
    interval + 's',
  )}`;
};

export const purchaseEventValue = (currency: string, amount: number) =>
  `${(amount / 100).toFixed(2)} ${currency.toLowerCase()}`;
