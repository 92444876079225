import { DATE_INTERVAL, IProductDescription, PageTypes } from 'types/types';

export function isPremium() {
  return window.location.host.includes('premium');
}

export function isRelations() {
  return window.location.host.includes('relations');
}

export function getPixelMarketingPrice(
  data: IProductDescription,
  paywallType: PageTypes,
): number {
  const { interval, intervalCount, trial, updateToProduct } = data;
  if (isPremium()) {
    return trial.amount / 100;
  }

  if (
    paywallType === PageTypes.PAYWALL_WOMAN_LONG ||
    paywallType === PageTypes.PAYWALL_MEN_LONG
  ) {
    if (
      interval === DATE_INTERVAL.DAY &&
      intervalCount === 7 &&
      updateToProduct
    ) {
      return 57.5;
    }

    if (interval === DATE_INTERVAL.DAY && intervalCount === 28) {
      return 70;
    }

    if (interval === DATE_INTERVAL.DAY && intervalCount === 84) {
      return 84;
    }
  }

  if (
    interval === DATE_INTERVAL.DAY &&
    intervalCount === 7 &&
    updateToProduct
  ) {
    return 55;
  }

  if (interval === DATE_INTERVAL.DAY && intervalCount === 28) {
    return 80;
  }

  if (interval === DATE_INTERVAL.DAY && intervalCount === 84) {
    return 90;
  }

  if (
    (interval === DATE_INTERVAL.WEEK && intervalCount === 1) ||
    (interval === DATE_INTERVAL.DAY && intervalCount === 7)
  ) {
    return 47;
  } else if (interval === DATE_INTERVAL.MONTH && intervalCount === 3) {
    return 73;
  } else {
    return 54;
  }
}
