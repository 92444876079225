import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { SubrouteProvider } from 'contexts/SubrouteContext/SubrouteProvider';
import { Suspense, useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components';
import { suspend } from 'suspend-react';

import { AppConfig } from 'config';

import { createStore, Provider } from 'stores/RootStore';

import { analitics } from 'services/analytics';
import { SensesSDK } from 'services/api/senses';
import { PixelServices } from 'services/pixelServices';
import { FirebaseService } from 'services/remoteConfig';
import { StripeService } from 'services/stripeService';

import { checkVersion } from 'utils/localStorage';

import { useDetectDeviceDOM } from 'hooks/useDetectDeviceDOM';
import { useWindowHeight } from 'hooks/useWindowHeight';

import { AppNavigator } from 'navigations/AppNavigator';

import { GlobalStyles } from 'theme/global';
import { lightTheme } from 'theme/theme';

import { LoadingView } from 'components/LoadingView/LoadingView';

smoothscroll.polyfill();

const stripeOptions: StripeElementsOptions = {
  locale: 'en',
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Fredoka&family=Inter:wght@400;500&display=swap',
    },
  ],
};

const AppContent = () => {
  const store = suspend(async () => {
    FirebaseService.init();
    const [persistedStore] = await Promise.all([createStore()]);
    SensesSDK.setAuthKey(persistedStore.authStore.token);
    return persistedStore;
  }, []);

  return (
    <Provider value={store}>
      <SubrouteProvider>
        <YMInitializer
          accounts={[AppConfig.YA_METRIKA_ID]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            defer: true,
          }}
          version="2"
        />
        <Elements
          options={stripeOptions}
          stripe={StripeService.stripeAppPromise}>
          <GlobalStyles />
          <AppNavigator />
        </Elements>
      </SubrouteProvider>
    </Provider>
  );
};

export function App() {
  useDetectDeviceDOM();
  useWindowHeight();

  useEffect(() => {
    analitics.init();
    PixelServices.init(AppConfig.FB_PIXEL_ID);
    PixelServices.snapInit(AppConfig.SNAP_PIXEL_ID);
    PixelServices.tiktokInit(AppConfig.TIKTOK_PIXEL_ID);
    PixelServices.pinterestInit(AppConfig.PINTEREST_PIXEL_ID);
    checkVersion();
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <Suspense fallback={<LoadingView />}>
        <AppContent />
      </Suspense>
    </ThemeProvider>
  );
}
