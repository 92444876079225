import { Timeout } from 'types/module';

export const delay = async (duration: number) => {
  return new Promise(resolve => setTimeout(resolve, duration));
};

export const deferredCall = <T>(
  callback: (props?: T) => void,
  limit: number,
) => {
  let timer: Timeout;

  return (props?: T) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      callback(props);
    }, limit);
  };
};

export const isTouchDevice = () =>
  'ontouchstart' in window ||
  //@ts-ignore
  !!(navigator!.msMaxTouchPoints || navigator!.maxTouchPoints);

const symbolsMap = {
  USD: '$',
  RUB: '₽',
  EUR: '€',
};

export const getCurrencySymbol = (value: string) => {
  value = value.toUpperCase();
  return symbolsMap[value as keyof typeof symbolsMap] ?? symbolsMap.USD;
};

export async function imageLoader(src: string) {
  return new Promise(function (resolve) {
    const image = document.createElement('img');
    image.setAttribute('src', src);
    image.addEventListener('load', function () {
      resolve(image);
    });
  });
}

export const getDiscountPercentage = (
  initialPrice: number,
  discountedPrice: number,
) => Math.floor(((initialPrice - discountedPrice) / initialPrice) * 100);
