import capitalize from 'lodash/capitalize';
import { DateTime, Duration } from 'luxon';

import {
  IProductDescription,
  ISubscriptionWithIntervalDate,
  PageTypes,
} from 'types/types';

import { getCurrencySymbol } from './common';

const WEEKS_IN_MONTH = 4;

export const numToStringMap = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
};

export const pluralization = (
  count: number,
  single: string,
  plural: string,
) => {
  return `${count === 1 ? single : plural}`;
};

export const formatTrialPrice = (productData?: IProductDescription) =>
  productData?.trial
    ? `${getCurrencySymbol(productData.currency)}${
        productData.trial.amount / 100
      }`
    : '';

export const formatIntervalPrice = ({
  amount = 0,
  interval,
  intervalCount = 1,
  currency = '',
  delimeter = '/',
}: Partial<IProductDescription> & { delimeter?: string }) => {
  const formattedInterval = interval?.toLowerCase() || '';
  const pluralizedInterval = formattedInterval.endsWith('s')
    ? formattedInterval
    : pluralization(intervalCount, formattedInterval, `${formattedInterval}s`);

  const parsedIntervalCount =
    delimeter === 'every' || delimeter === 'after'
      ? numToStringMap[
          intervalCount as keyof typeof numToStringMap
        ].toLocaleLowerCase()
      : intervalCount;
  const desc = `${delimeter} ${
    intervalCount > 1 ? `${parsedIntervalCount} ` : ''
  }${pluralizedInterval}`;

  return `${getCurrencySymbol(currency)}${amount / 100} ${desc}`;
};

export const formatProductsData = (
  productData?: IProductDescription,
  type?: PageTypes,
) => {
  const result = [];
  const MAX_LENGTH = 2;

  if (productData) {
    const { trial } = productData;
    if (productData) {
      const topTitle = trial ? 'Price after trial' : 'Price';
      const priceTitle = `${
        productData.intervalCount
      }-${productData.interval.toLowerCase()} plan`;
      const pricePrimary = `${getCurrencySymbol(productData.currency)}${(
        productData.amount /
        100 /
        (WEEKS_IN_MONTH * productData.intervalCount)
      ).toFixed(2)} / week`;
      const priceSecondary = `${getCurrencySymbol(productData.currency)}${
        productData.amount / 100
      }`;

      result.push({ topTitle, priceTitle, pricePrimary, priceSecondary });

      if (trial) {
        const trialTopTitle = 'Trial';
        const trialPriceTitle = `${trial.intervalCount}-day trial`;
        const trialPricePrimary = `${getCurrencySymbol(productData.currency)}${
          trial.amount / 100
        }`;

        const trialPlan = {
          topTitle: trialTopTitle,
          priceTitle: trialPriceTitle,
          pricePrimary: trialPricePrimary,
          priceSecondary,
          isUnlocked: true,
        };
        result.unshift(trialPlan);
      }
    }
  }
  if (
    (type === PageTypes.BRAVO ||
      type === PageTypes.CHARLIE ||
      type === PageTypes.DELTA) &&
    result.length === MAX_LENGTH
  ) {
    const [trialData, mainData] = result;
    return [
      {
        topTitle: `Try ${trialData.priceTitle} for ${trialData.pricePrimary} 🎉`,
        priceTitle: `Then ${mainData.priceTitle}`,
        pricePrimary: mainData.pricePrimary,
        priceSecondary: `Billed at ${mainData.priceSecondary}`,
      },
    ];
  }

  return result;
};

export const formatMenuSubscriptionTitle = (
  sub: ISubscriptionWithIntervalDate,
) => {
  return `${
    sub.intervalCount > 0 && sub.intervalCount < 10
      ? numToStringMap[sub.intervalCount as keyof typeof numToStringMap]
      : sub.intervalCount
  }-${capitalize(sub.interval)} Subscription`;
};

export const formatRenewsDate = (
  currentSubscriptionData: ISubscriptionWithIntervalDate,
) => {
  const isCanceled = !!currentSubscriptionData.cancellationTimestamp;
  const date = DateTime.fromMillis(currentSubscriptionData.to).toFormat('DDD');

  return `${isCanceled ? 'Expires' : 'Renews'} on ${date}`;
};

export const formatIntervalTitle = (interval: string) =>
  `${capitalize(interval)}ly Subscription`;

export const formatTrialDateEnd = (data: IProductDescription) => {
  const { interval, intervalCount } = data.trial;

  return DateTime.local()
    .plus({ [interval.toLowerCase()]: intervalCount })
    .toFormat('DDD');
};

export const getMultilineTextNode = (text: string, delimeter = '\\n') => {
  return text.split(delimeter).map(node => <p key={node}>{node}</p>);
};

export const stripTags = (strInputCode: string) =>
  strInputCode.replace(/<\/?[^>]+(>|$)/g, '');

export const removeTags = (html: string, tags: string[]) => {
  return tags.reduce((acc, tName) => {
    const re = new RegExp(`<${tName}.*?>(.|\n|\r)*?</${tName}>`, 'gi');
    return acc.replace(re, '');
  }, html);
};

export const serializeQueryParams = (
  obj: Record<string, string | number | boolean>,
) => Object.entries(obj).reduce((ac, [key, val]) => `${ac}&${key}=${val}`, '');

export const getMMSSfromSeconds = (timeInSeconds: number) =>
  Duration.fromMillis(timeInSeconds * 1000).toFormat('mm:ss');
