import { formatPrice } from 'utils';

import {
  DAYS_IN_DATE_INTERVAL,
  IProductDescription,
  ITrial,
} from 'types/types';

import { getCurrencySymbol } from 'utils/common';

// TODO лучшее решение - держать все функции в отдельных файлах, и создать папки stripe, paypal, common, тк очень много вспомогательных функций

type ILightProduct = Omit<IProductDescription, 'price_id' | 'active' | 'trial'>;

export const getPricePerDayTitle = (product?: ILightProduct) => {
  if (!product) {
    return '';
  }

  const { currency, amount, interval, intervalCount } = product;

  const daysInInterval = DAYS_IN_DATE_INTERVAL[interval] * intervalCount;

  const currencySymbol = getCurrencySymbol(currency);
  const priceAmount = formatPrice(amount / daysInInterval);

  const priceTitle = `${currencySymbol}${priceAmount}`;

  return priceTitle;
};

const countNumericDifference = (from: number, to: number) => {
  return (from - to).toFixed(2);
};

export const countPricesPercentDifference = (from: number, to: number) => {
  return `${(((from - to) / from) * 100).toFixed(0)}%`;
};

export const getPricesBlockTitles = (
  trial: ITrial,
  product: IProductDescription,
) => {
  const currencySymbol = getCurrencySymbol(product.currency);

  const oldPrice = product.amount / 100;
  const newPrice = trial.amount / 100;

  const pricesDifferenceInNumber = countNumericDifference(oldPrice, newPrice);
  const pricesDifferenceInPercents = countPricesPercentDifference(
    oldPrice,
    newPrice,
  );

  const oldPriceTitle = formatPrice(product.amount);
  const newPriceTitle = formatPrice(trial.amount);

  return {
    oldPriceTitle,
    newPriceTitle,
    currencySymbol,
    pricesDifferenceInNumber,
    pricesDifferenceInPercents,
  };
};
